<template>
  <v-container id="promocode" fluid tag="section">
    <v-card>
      <v-alert color="red lighten-2" dark v-if="notAllowedMoreThanSpendAmount">
        Not Enter More than Spend Amount ({{ spend_amount }})
      </v-alert>
    </v-card>
    <v-card>
      <v-alert color="red lighten-2" dark v-if="notAllowedMoreThanPercentage">
        Not Enter More than 100%
      </v-alert>
    </v-card>
    <v-card>
      <v-alert color="red lighten-2" dark v-if="showItemError">
        {{ showErrorName }}
      </v-alert>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Title"
                class="purple-input"
                v-model="name"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Promocode"
                class="purple-input"
                v-model="name_promocodes"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="6" md="6">
              <v-text-field
                label="Spends Amount"
                type="number"
                value="0.00"
                class="purple-input"
                @keypress="isPriceKey"
                v-model="spend_amount"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Amount"
                type="number"
                value="0.00"
                class="purple-input"
                @keypress="isPriceKey"
                v-model="discount_amount"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>

            <v-col cols="3" md="3">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="validDatesRange[0]"
                    label="Validity Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    :disabled="onlyView"
                    v-bind="attrs"
                    v-on="on"
                    :rules="inputRule"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="validDatesRange[0]"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" md="3">
              <v-menu
                ref="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="validDatesRange[1]"
                    label="Validity End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    :disabled="onlyView"
                    v-bind="attrs"
                    v-on="on"
                    :rules="inputRule"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="validDatesRange[1]"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="6">
              <v-textarea
                class="purple-input"
                label="Description"
                v-model="description"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
                :disabled="notValidData"
              >
                {{
                  onlyView == true ? "Back" : editPromocode == true ? "Update" : "Submit"
                }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      name: "",
      description: "",
      spend_amount: null,
      discount_amount: null,
      name_promocodes: "",
      validDatesRange: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],
      promocodes: [],
      editPromocode: null,
      loading: false,
      inputRule: [(v) => !!v || "Field is required"],
      onlyView: false,
      showItemError: false,
      showErrorName: "",
      notAllowedMoreThanSpendAmount: false,
      notAllowedMoreThanPercentage: false,
      notValidData: false,
    };
  },
  methods: {
    isPriceKey(event) {
      const reg = /^-?\d*(\.\d{0,2})?$/;
      let input = event.target.value + String.fromCharCode(event.charCode);

      if (!reg.test(input)) {
        event.preventDefault();
      }
    },
    getPromocodeData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "promocode/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.name = response.data.promocode.name;
            this.description = response.data.promocode.description;
            this.discount_amount = response.data.promocode.discount_amount;
            this.name_promocodes = response.data.promocode.name_promocodes;
            this.validDatesRange = response.data.promocode.valid_dates_range;
            this.spend_amount = response.data.promocode.spend_amount;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    calculatePercentage() {
      if (parseInt(this.spend_amount) < parseInt(this.discount_amount)) {
        this.notAllowedMoreThanSpendAmount = true;
        this.notValidData = true;
      } else {
        this.notAllowedMoreThanSpendAmount = false;
        this.notValidData = false;
        let discountPercentage = (this.discount_amount / this.spend_amount) * 100;
      }
    },
    calculateAmount() {
      if (this.discount_percentage > 100) {
        this.notAllowedMoreThanPercentage = true;
        this.notValidData = true;
      } else {
        this.notAllowedMoreThanPercentage = false;
        this.notValidData = false;
        this.discount_amount = discountAmount.toFixed(2);
      }
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          id: this.id,
          name: this.name,
          description: this.description,
          spend_amount: this.spend_amount,
          discount_amount: this.discount_amount,
          name_promocodes: this.name_promocodes,
          valid_dates_range: this.validDatesRange,
        };
        if (this.onlyView) {
          this.$router.push({ name: "Promocode" });
        } else if (this.editPromocode == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "promocode/update", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Promocode",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showItemError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "promocode/create", data)
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "Promocode", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              if (error.response.data.name != null) {
                this.showItemError = true;
                this.showErrorName = error.response.data.name[0];
              }
            });
        }
      }
    },
  },
  mounted() {
    if (this.id != undefined) {
      this.getPromocodeData(this.id);
      this.editPromocode = true;
    } else {
      this.editPromocode = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>

<style>
.v-card {
  border-radius: 6px;
  margin-top: 0px !important;
  margin-bottom: 15px;
}
</style>
